*{
    margin: 0;
    padding: 0;
}

.collapsess{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: center;
}
.headerbg{
	background-color: #45526e;
}
.bannerbg{
	background-color: #333399!important;
}
.rankbannerbg{
	background-color: #33339917!important;
}

.formbg{
	background-color: #ffffff!important;
}
.instibuddy-bg{
  background-color: #f47f20!important;
}
.instibuddy-bgtext{
  background-color:#ffffffa6!important;
}

.instibuddy-bg2{
  background-color: #22232d!important;
}
.instibuddy-bg3{
  background-color: #ffcc66!important;
}
.instibuddy-bg4{
  background-color: #2c2c2d!important;
}
.remove{
  transition: none !important;
  box-shadow: none !important;
}
.card1{
 background-color: #118f240a!important;
}
.paddingg{
  padding: 0rem!important;
}
.font-head{
  font-family: 'Playfair Display', serif;
}