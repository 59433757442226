.d-flex div{
    /* background-color: rgb(241 239 239); */
    /* border: 1px solid rgb(241 239 239); */
    color: #fff;
    text-align: center;
  }
  .img-msg{
    height: 36px;
    width: 36px;
    
  }
  .bg-msg-f{
      background-color: rgb(241 239 239);
  }

  .d-flex div{
      padding: 0rem!important;
  }
  .main {
    margin-left: 200px; /* Same as the width of the sidenav */
 /* Increased text to enable scrolling */
    padding: 0px 10px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }