.bg-gradient-default {
    background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important; }
    .bg-gradient-info {
        background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important; }
        .bg-gradient-instibuddy { 
            background: linear-gradient(87deg, #f47a3f 0, #f0512e 90%) !important;
        }
        .font-body {
            font-family: 'Quicksand', sans-serif;
        }
        .font-heading{
            font-family: 'Playfair Display', serif;
        }